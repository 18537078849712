



















import mixins from 'vue-typed-mixins'
import AboutUs from '@/builder/sections/section/gallery/mixins/Gallery'
export default mixins(AboutUs).extend({
  name: 'Gallery2',
  data () {
    return {
      model: 0
    }
  }
})
